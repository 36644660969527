import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { TabMenuModule } from 'primeng/tabmenu';
import { AvatarModule } from 'primeng/avatar';
import { BreadcrumbModule } from 'primeng/breadcrumb';

import { MenuModule } from 'primeng/menu';
import { BadgeModule } from 'primeng/badge';
import { MegaMenuModule } from 'primeng/megamenu';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputMaskModule } from 'primeng/inputmask';
import { MultiSelectModule } from 'primeng/multiselect';

const primeNgModules = [
    ToastModule,
    TabMenuModule,
    BreadcrumbModule,
    AvatarModule,
    BadgeModule,
    MenuModule,
    MegaMenuModule,
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    FormsModule,
    CheckboxModule,
    MessagesModule,
    MessageModule,
    TableModule,
    ButtonModule,
    TieredMenuModule,
    CalendarModule,
    ProgressSpinnerModule,
    StepsModule,
    CardModule,
    AccordionModule,
    FileUploadModule,
    DialogModule,
    OverlayPanelModule,
    InputMaskModule,
    MultiSelectModule,
];
@NgModule({
    declarations: [],
    providers: [ConfirmationService, MessageService],
    exports: primeNgModules,
    imports: primeNgModules,
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class PrimenNgModule {}
